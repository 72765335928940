<!-- TwoAthletesSoccerBackground.vue -->

<template>
	<div class="d-flex">
		<v-img v-bind="$attrs" contain :src="imgAthleteSoccerBackgroundLeft">
			<slot></slot>
		</v-img>
		<v-spacer></v-spacer>
		<v-img v-bind="$attrs" contain :src="imgAthleteSoccerBackgroundRight">
			<slot></slot>
		</v-img>
	</div>
</template>
<script>
import imgAthleteSoccerBackgroundRight from '../../assets/img/athlete-soccer-background-right.svg'
import imgAthleteSoccerBackgroundLeft from '../../assets/img/athlete-soccer-background-left.svg'
export default{
	data(){
		return { imgAthleteSoccerBackgroundRight, imgAthleteSoccerBackgroundLeft }
	}
}
</script>