<!-- AthleteBackground.vue -->
<!-- TwoAthletesSoccerBackground.vue -->

<template>
	<div class="d-flex">
		<v-img contain width="13%" :src="pngSoccer" />
		<v-spacer />
		<v-img contain width="12%" :src="pngRunning" />
		<v-spacer />
		<v-img contain width="12%" :src="pngLacrosse" />
		<v-spacer />
		<v-img contain width="12%" :src="pngRugby" />
		<v-spacer />
		<v-img contain width="13%" :src="pngTennis" />
		<v-spacer />
		<v-img contain width="12%" :src="pngVolleyball" />
		<v-spacer />
		<v-img contain width="13%" :src="pngHockey" />
		<v-spacer />
		<v-img contain width="13%" :src="pngBasketball" />
	</div>
</template>

<script>
export default{
	data(){
		return {
			pngSoccer: require('../../assets/img/olympic-soccer.png'),
			pngRunning: require('../../assets/img/olympic-running.png'),
			pngLacrosse: require('../../assets/img/olympic-lacrosse.png'),
			pngRugby: require('../../assets/img/olympic-rugby.png'),
			pngTennis: require('../../assets/img/olympic-tennis.png'),
			pngVolleyball: require('../../assets/img/olympic-volleyball.png'),
			pngHockey: require('../../assets/img/olympic-hockey.png'),
			pngBasketball: require('../../assets/img/olympic-basketball.png'),
		};
	}
}
</script>