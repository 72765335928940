
import Vue from 'vue';
import Page from '../views/Page.vue';
import Navbar from '../components/navigation/Navbar.vue';
import AthleteBackground from '../components/svg/AthleteBackground.vue';
// import AthleteSoccerBackground from '../components/svg/AthleteSoccerBackground.vue';
import TwoAthletesSoccerBackground from '../components/svg/TwoAthletesSoccerBackground.vue';

export default Vue.extend({
	props:{
		role: {
			// This prop can be used to render alternate backgrounds for Coaches, Athletes, etc.
			type: String,
			default: null,
		}
	},
	components: {
		Page,
		Navbar,
		TwoAthletesSoccerBackground,
		AthleteBackground,
	},
});
